/**
 * Generated by `createschema ticket.IncidentExportTask 'status:Select:processing,completed,error; format:Select:excel; exportedRecordsCount:Integer; totalRecordsCount:Integer; file?:File; meta?:Json; where:Json; sortBy:Json; locale:Text; timeZone:Text; user:Relationship:User:CASCADE;'`
 */

import {
    IncidentExportTask,
    IncidentExportTaskCreateInput,
    IncidentExportTaskUpdateInput,
    QueryAllIncidentExportTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { IncidentExportTask as IncidentExportTaskGQL } from '@condo/domains/ticket/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<IncidentExportTask, IncidentExportTaskCreateInput, IncidentExportTaskUpdateInput, QueryAllIncidentExportTasksArgs>(IncidentExportTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
