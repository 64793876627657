/**
 * Generated by `createschema organization.Organization 'country:Select:ru,en; name:Text; description?:Text; avatar?:File; meta:Json; employees:Relationship:OrganizationEmployee:CASCADE; statusTransitions:Json; defaultEmployeeRoleStatusTransitions:Json' --force`
 */

import {
    Organization,
    OrganizationCreateInput,
    OrganizationUpdateInput,
    QueryAllOrganizationsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { Organization as OrganizationGQL } from '@condo/domains/organization/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<Organization, OrganizationCreateInput, OrganizationUpdateInput, QueryAllOrganizationsArgs>(OrganizationGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
