const ALREADY_ACCEPTED_INVITATION = 'ALREADY_ACCEPTED_INVITATION'
const ALREADY_INVITED_EMAIL = 'ALREADY_INVITED_EMAIL'
const ALREADY_INVITED_PHONE = 'ALREADY_INVITED_PHONE'
const EMPTY_NAME_ERROR = '[name.is.too.short'
const TIN_TOO_SHORT_ERROR = '[tin.is.too.short'
const TIN_VALUE_INVALID = '[tin.value.is.invalid'
const UNABLE_TO_REGISTER_USER = 'UNABLE_TO_REGISTER_USER'
const PARENT_NOT_HOLDING = 'PARENT_NOT_HOLDING'

module.exports = {
    ALREADY_ACCEPTED_INVITATION,
    ALREADY_INVITED_EMAIL,
    ALREADY_INVITED_PHONE,
    EMPTY_NAME_ERROR,
    TIN_TOO_SHORT_ERROR,
    TIN_VALUE_INVALID,
    UNABLE_TO_REGISTER_USER,
    PARENT_NOT_HOLDING,
}
