/**
 * Generated by `createschema ticket.IncidentClassifier 'organization?:Relationship:Organization:CASCADE;category?:Relationship:TicketCategoryClassifier:PROTECT;problem?:Relationship:TicketProblemClassifier:PROTECT;'`
 */

import {
    IncidentClassifier,
    IncidentClassifierCreateInput,
    IncidentClassifierUpdateInput,
    QueryAllIncidentClassifiersArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { IncidentClassifier as IncidentClassifierGQL } from '@condo/domains/ticket/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<IncidentClassifier, IncidentClassifierCreateInput, IncidentClassifierUpdateInput, QueryAllIncidentClassifiersArgs>(IncidentClassifierGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
