/**
 * Generated by `createschema ticket.IncidentProperty 'incident:Relationship:Incident:CASCADE; property:Relationship:Property:PROTECT; propertyAddress:Text; propertyAddressMeta;'`
 */

import {
    IncidentProperty,
    IncidentPropertyCreateInput,
    IncidentPropertyUpdateInput,
    QueryAllIncidentPropertiesArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { IncidentProperty as IncidentPropertyGQL } from '@condo/domains/ticket/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
} = generateReactHooks<IncidentProperty, IncidentPropertyCreateInput, IncidentPropertyUpdateInput, QueryAllIncidentPropertiesArgs>(IncidentPropertyGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
}
