/**
 * Generated by `createschema ticket.TicketCategoryClassifier 'organization?:Relationship:Organization:CASCADE;name:Text;'`
 */

import {
    TicketCategoryClassifier,
    TicketCategoryClassifierCreateInput,
    TicketCategoryClassifierUpdateInput,
    QueryAllTicketCategoryClassifiersArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { TicketCategoryClassifier as TicketCategoryClassifierGQL } from '@condo/domains/ticket/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<TicketCategoryClassifier, TicketCategoryClassifierCreateInput, TicketCategoryClassifierUpdateInput, QueryAllTicketCategoryClassifiersArgs>(TicketCategoryClassifierGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
