/**
 * Generated by `createschema ticket.TicketDocumentGenerationTask 'where:Json; status:Select:processing,completed,error,cancelled; format:Select:docx; progress:Integer; user:Relationship:User:CASCADE; timeZone:Text; file?:File; certificateType:Select:completion; meta?:Json;'`
 */

import {
    TicketDocumentGenerationTask,
    TicketDocumentGenerationTaskCreateInput,
    TicketDocumentGenerationTaskUpdateInput,
    QueryAllTicketDocumentGenerationTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { TicketDocumentGenerationTask as TicketDocumentGenerationTaskGQL } from '@condo/domains/ticket/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<TicketDocumentGenerationTask, TicketDocumentGenerationTaskCreateInput, TicketDocumentGenerationTaskUpdateInput, QueryAllTicketDocumentGenerationTasksArgs>(TicketDocumentGenerationTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
