/**
 * Generated by `createschema ticket.TicketClassifier 'organization?:Relationship:Organization:CASCADE;place?:Relationship:TicketPlaceClassifier:PROTECT;category?:Relationship:TicketCategoryClassifier:PROTECT;problem?:Relationship:TicketProblemClassifier:PROTECT;'`
 */

import {
    TicketClassifier,
    TicketClassifierCreateInput,
    TicketClassifierUpdateInput,
    QueryAllTicketClassifiersArgs,
    TicketClassifierWhereInput,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { TicketClassifier as TicketClassifierGQL } from '@condo/domains/ticket/gql'

export type ITicketClassifierWhereInput = Pick<TicketClassifierWhereInput, 'organization' | 'organization_is_null' | 'id' | 'place' | 'category' | 'problem'>

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<TicketClassifier, TicketClassifierCreateInput, TicketClassifierUpdateInput, QueryAllTicketClassifiersArgs>(TicketClassifierGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
