/**
 * Generated by `createschema organization.OrganizationEmployee 'organization:Relationship:Organization:CASCADE; user:Relationship:User:SET_NULL; inviteCode:Text; name:Text; email:Text; phone:Text; role:Relationship:OrganizationEmployeeRole:SET_NULL; isAccepted:Checkbox; isRejected:Checkbox' --force`
 */

import {
    OrganizationEmployee,
    OrganizationEmployeeCreateInput,
    OrganizationEmployeeUpdateInput,
    QueryAllOrganizationEmployeesArgs,
} from '@app/condo/schema'
import { get, omit } from 'lodash'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { OrganizationEmployee as OrganizationEmployeeGQL } from '@condo/domains/organization/gql'

function convertGQLItemToFormSelectState (item: OrganizationEmployee): { value: string, label: string } | undefined {
    const userOrganization = get(item, 'organization')
    if (!userOrganization) {
        return
    }

    const { name } = userOrganization

    return { value: item.id, label: name }
}

function formValuesProcessor (formValues): OrganizationEmployeeCreateInput | OrganizationEmployeeUpdateInput {
    const input = omit(formValues, ['specializations', 'role'])
    if (formValues['role']) {
        input['role'] = { connect: { id: formValues['role'] } }
    }

    return input
}

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
    useCount,
} = generateReactHooks<OrganizationEmployee, OrganizationEmployeeCreateInput, OrganizationEmployeeUpdateInput, QueryAllOrganizationEmployeesArgs>(OrganizationEmployeeGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
    convertGQLItemToFormSelectState,
    formValuesProcessor,
    useCount,
}
