/**
 * Generated by `createschema ticket.TicketComment 'ticket:Relationship:Ticket:CASCADE; user:Relationship:User:CASCADE; content:Text;'`
 */

import {
    TicketComment,
    TicketCommentCreateInput,
    TicketCommentUpdateInput,
    QueryAllTicketCommentsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { TicketComment as TicketCommentGQL } from '@condo/domains/ticket/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<TicketComment, TicketCommentCreateInput, TicketCommentUpdateInput, QueryAllTicketCommentsArgs>(TicketCommentGQL)


export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
