/**
 * Generated by `createschema ticket.CallRecord 'organization:Relationship:Organization:CASCADE;file?:File;callerPhone:Text;destCallerPhone:Text;talkTime:Integer;startDate:DateTimeUtc;isIncomingCall:Checkbox;importId:Text;'`
 */

import {
    CallRecord,
    CallRecordCreateInput,
    CallRecordUpdateInput,
    QueryAllCallRecordsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { CallRecord as CallRecordGQL } from '@condo/domains/ticket/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<CallRecord, CallRecordCreateInput, CallRecordUpdateInput, QueryAllCallRecordsArgs>(CallRecordGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
