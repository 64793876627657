/**
 * Generated by `createschema ticket.IncidentClassifierIncident 'incident:Relationship:Incident:CASCADE; classifier:Relationship:IncidentClassifier:PROTECT;'`
 */

import {
    IncidentClassifierIncident,
    IncidentClassifierIncidentCreateInput,
    IncidentClassifierIncidentUpdateInput,
    QueryAllIncidentClassifierIncidentsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { IncidentClassifierIncident as IncidentClassifierIncidentGQL } from '@condo/domains/ticket/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
} = generateReactHooks<IncidentClassifierIncident, IncidentClassifierIncidentCreateInput, IncidentClassifierIncidentUpdateInput, QueryAllIncidentClassifierIncidentsArgs>(IncidentClassifierIncidentGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useAllObjects,
}
